import { Pipe, PipeTransform } from '@angular/core';
import { ProductAndRatePojo } from '../pojos/product-master.pojo';

declare var _: any;

@Pipe({
    name: 'productDetails'
})

export class ProductDetailsPipe implements PipeTransform {

    transform(array: ProductAndRatePojo[], query: string, queryTwo: number): any {
        if (query) {
            let j = _.filter(array, (row: { productMasterPojo: { productCode: string; }; }) => (row.productMasterPojo.productCode + "").indexOf(query) > -1);
            let k = _.filter(array, (row: { productMasterPojo: { barCode: string; }; }) => (row.productMasterPojo.barCode + "").indexOf(query) > -1);
            if (j != '') {
                return _.slice(j, 0, queryTwo);
            } else if (k != '') {
                return _.slice(k, 0, queryTwo);
            } else {
                let l = _.filter(array, (row: { productMasterPojo: { productName: string; }; }) => row.productMasterPojo.productName.toLowerCase().indexOf(query.toLowerCase()) > -1);
                if (l != '')
                    return _.slice(l, 0, queryTwo);
            }
            return;
        }
        return _.slice(array, 0, 100);
    }

}

@Pipe({
    name: 'productNameMaster'
})

export class ProductNameMasterPipe implements PipeTransform {

    transform(array: ProductAndRatePojo[], query: string, queryTwo: number): any {
        if (query) {
            let j = _.filter(array, (row: { productMasterPojo: { productCode: string; }; }) => row.productMasterPojo.productCode.toLowerCase().indexOf(query) > -1);
            if (j != '') {
                return _.slice(j, 0, queryTwo);
            } else {
                let l = _.filter(array, (row: { productMasterPojo: { productName: string; }; }) => row.productMasterPojo.productName.toLowerCase().indexOf(query.toLowerCase()) > -1);
                if (l != '')
                    return _.slice(l, 0, queryTwo);
            }
            return;
        }
        return _.slice(array, 0, 100);
    }
}

@Pipe({
    name: 'productName'
})

export class ProductNamePipe implements PipeTransform {

    transform(array: ProductAndRatePojo[], query: string, queryTwo: number): any {
        if (query) {
            let j = _.filter(array, (row: { productMasterPojo: { productCode: string; }; }) => (row.productMasterPojo.productCode + "").indexOf(query) > -1);
            if (j != '') {
                return _.slice(j, 0, queryTwo);
            } else {
                let l = _.filter(array, (row: { productMasterPojo: { productName: string; }; }) => row.productMasterPojo.productName.toLowerCase().indexOf(query.toLowerCase()) > -1);
                if (l != '')
                    return _.slice(l, 0, queryTwo);
            }
            return;
        }
        return _.slice(array, 0, 100);
    }
}

@Pipe({
    name: 'subTypeName'
})

export class ProductSubTypeNamePipe implements PipeTransform {

    transform(array: any[], query: string, queryTwo: number): any {
        if (query) {
            let l = _.filter(array, (row: { subType: string; }) => row.subType.toLowerCase().indexOf(query.toLowerCase()) > -1);
            if (l != '') {
                return _.slice(l, 0, queryTwo);
            }
            return;
        }
        return _.slice(array, 0, 100);
    }

}
@Pipe({
    name: 'productAndRateMasterList'
})

export class ProductAndRateMasterListPipe implements PipeTransform {

    transform(array: any[], query: string, queryTwo: number): any {
        if (query) {
            let l = _.filter(array, (row: { productMasterPojo: { productCode: string; }; }) => row.productMasterPojo.productCode.toLowerCase().indexOf(query.toLowerCase()) > -1);
            if (l != '') {
                return _.slice(l, 0, queryTwo);
            }
            return;
        }
        return _.slice(array, 0, 100);
    }

}
