import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-spinne-loading',
  templateUrl: './spinne-loading.component.html',
  styleUrls: ['./spinne-loading.component.scss']
})
export class SpinneLoadingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
